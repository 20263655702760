<template>
  <IconButton
    :icon="shouldShowUserMenu ? Icon.CLOSE_V2 : Icon.HAMBURGER"
    class="h-md border-none dark:invert"
    @click="shouldShowUserMenu = !shouldShowUserMenu"
  />
  <Teleport v-if="shouldShowUserMenu" to="body">
    <div
      class="fixed bottom-0 left-0 right-0 top-0 z-10"
      :class="{
        'bg-lightPrimaryBackgroundColor/70 dark:bg-darkPrimaryBackgroundColor/70': shouldShowUserMenu,
      }"
      @click="shouldShowUserMenu = false"
    />
  </Teleport>
  <Teleport v-if="shouldShowUserMenu" to="#header">
    <div class="absolute right-0 ml-auto w-full">
      <div
        class="ml-auto h-full w-full rounded-lg bg-lightPrimaryColor p-4 shadow-xl dark:bg-darkPrimaryColor dark:shadow-xl md:w-fit"
      >
        <div class="grid gap-sm p-sm">
          <UserMenuButton
            :icon="Icon.HOME"
            :text="$t('message.home')"
            :to="{ name: 'home' }"
            @click="shouldShowUserMenu = !shouldShowUserMenu"
          />
          <DividerBar />
          <template v-if="userMandants && userMandants.length > 1 && userMandants.length < 5">
            <div
              v-for="(mandant, index) in userMandants"
              :key="index"
              class="flex items-center justify-center rounded-lg p-2 hover:bg-primary/20 dark:hover:bg-primary/20"
            >
              <router-link
                :to="{ name: 'mandantDashboard', params: { mandantId: mandant.id } }"
                class="h-full"
                @click="shouldShowUserMenu = !shouldShowUserMenu"
              >
                {{ mandant.id + " - " + mandant.name }}
              </router-link>
            </div>
            <DividerBar v-if="userMandants" />
          </template>
          <template v-if="currentMandant">
            <UserMenuButton
              :text="$t('message.notifications')"
              :to="{ name: 'notifications' }"
              @click="shouldShowUserMenu = !shouldShowUserMenu"
            >
              <template #icon>
                <NotificationTag />
              </template>
            </UserMenuButton>
            <DividerBar />
          </template>
          <UserMenuButton
            v-if="userId"
            :icon="Icon.GUEST"
            :text="$t('pages.profileSettings')"
            :to="{ name: 'userProfile' }"
            @click="shouldShowUserMenu = !shouldShowUserMenu"
          />
          <UserMenuButton
            :icon="Icon.REFRESH"
            :text="$t('message.news')"
            :to="{ name: 'releaseNotesPublic' }"
            @click="shouldShowUserMenu = !shouldShowUserMenu"
          />
          <UserMenuButton
            v-if="userId"
            :icon="Icon.CUSTOMER_SERVICE"
            :text="$t('pages.support')"
            :should-open-in-new-tab="true"
            href="https://support.cloud.anex.is/servicedesk/customer/portal/5"
            @click="shouldShowUserMenu = !shouldShowUserMenu"
          />
          <UserMenuButton
            :icon="Icon.LOGOUT"
            :text="$t('message.logOut')"
            :to="{ name: 'logout' }"
            @click="shouldShowUserMenu = !shouldShowUserMenu"
          />
        </div>
      </div>
      <slot />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { Ref, ref, watch } from "vue";

import { Icon } from "@/assets/icons/Icon";
import DividerBar from "@/components/DividerBar.vue";
import IconButton from "@/components/IconButton.vue";
import UserMenuButton from "@/components/UserMenuButton.vue";
import { useMandant } from "@/composition-functions/mandant";
import { IGetMandantsByIdsResponse } from "@/composition-functions/mandant/IResponses";
import { useUser } from "@/composition-functions/user";
import NotificationTag from "@/notification/components/NotificationTag.vue";

const { getMandantsByIds } = useMandant();
const { userId, mandantIds } = useUser();
const { currentMandant } = useMandant();
const userMandants: Ref<null | IGetMandantsByIdsResponse[]> = ref(null);
const shouldShowUserMenu: Ref<boolean> = ref(false);

watch(
  () => mandantIds,
  async () => {
    if (mandantIds.value.length === 0) {
      return;
    }
    const getMandantsByIdsResult = await getMandantsByIds({ mandantIds: mandantIds.value.slice(0, 5) });

    userMandants.value = getMandantsByIdsResult.items;
  },
  { immediate: true },
);
</script>
