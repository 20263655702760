import { Language } from "@/composition-functions/language/Language";
import { LanguageIsoCode } from "@/composition-functions/language/LanguageIsoCode";
import { assertNever } from "./assertNever";

const mapLanguageToApplicationLanguageIsoCode = (language: Language): LanguageIsoCode => {
  switch (language) {
    case Language.NONE:
    case Language.AUSTRIAN:
    case Language.BELGIAN:
    case Language.CROATIAN:
    case Language.CZECH:
    case Language.DANISH:
    case Language.DUTCH:
    case Language.ENGLISH:
    case Language.FRENCH:
    case Language.GERMAN:
    case Language.HUNGARIAN:
    case Language.LIECHTENSTEIN:
    case Language.RUSSIAN:
    case Language.POLISH:
    case Language.SWEDISH:
    case Language.SWISS:
      return LanguageIsoCode.GERMAN;
    case Language.ITALIAN:
      return LanguageIsoCode.ITALIAN;
    default:
      assertNever(language);
  }
};

export default mapLanguageToApplicationLanguageIsoCode;
