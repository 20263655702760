import { IGenericFailureResult } from "@/interfaces/IResults";
import { StatusCodes } from "http-status-codes";

interface GenericFailureParams {
  statusCode: StatusCodes;
  message: string;
  errors?: unknown;
  description?: undefined | string;
}

export class GenericFailure extends Error implements IGenericFailureResult {
  override name = "GenericFailure";
  success = false as const;
  statusCode: StatusCodes;
  errors?: unknown;
  description?: undefined | string;

  constructor({ statusCode, message, errors, description }: GenericFailureParams) {
    super(message);
    this.statusCode = statusCode;
    this.errors = errors;
    this.description = description;
  }
}

export class ValidationError extends GenericFailure {
  constructor(genericFailure: GenericFailure) {
    super({
      message: genericFailure.message,
      statusCode: genericFailure.statusCode,
      description: genericFailure.description,
      errors: genericFailure.errors,
    });

    this.name = "ValidationError";
  }
}
