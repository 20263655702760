<template>
  <component
    :is="props.to ? 'RouterLink' : 'a'"
    :to="props.to"
    :href="props.href"
    :target="props.shouldOpenInNewTab ? '_blank' : '_self'"
    :class="{ 'pointer-events-none': props.disabled || (props.to === undefined && props.href === undefined) }"
    class="flex h-xl cursor-pointer items-center gap-xs rounded-xl px-sm hover:bg-primary/20"
  >
    <slot name="icon">
      <BaseIcon v-if="props.icon" :icon="props.icon" />
    </slot>
    <slot name="text">
      {{ props.text }}
    </slot>
  </component>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";

import { Icon } from "@/assets/icons/Icon";
import BaseIcon from "@/components/BaseIcon.vue";

const props = defineProps<{
  icon?: Icon;
  text: string;
  to?: RouteLocationRaw;
  href?: string;
  disabled?: boolean;
  shouldOpenInNewTab?: boolean;
}>();
</script>
