import { CaptureContext } from "@sentry/types";
import * as Sentry from "@sentry/vue";
import { App } from "vue";

import { config } from "@/config/config";

let isPageUnloading = false;

interface InitializeLoggingParams {
  app: App<Element>;
}
export function initializeLogging({ app }: InitializeLoggingParams): void {
  if (config.isProduction) {
    Sentry.init({
      app,
      dsn: config.sentry.dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.globalHandlersIntegration({
          onerror: false,
          onunhandledrejection: false,
        }),
      ],
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
      normalizeDepth: 5,
    });
  }
}

type LogParams<ErrorType = unknown> = {
  error: ErrorType;
  context?: undefined | CaptureContext;
};
export function log({ error, context }: LogParams): void {
  if (isPageUnloading) {
    return;
  }

  if (error instanceof Error) {
    logException({
      error,
      context,
    });
  } else if (typeof error === "string") {
    logMessage({
      error,
      context,
    });
  } else if (error instanceof PromiseRejectionEvent) {
    logMessage({
      error: "Unhandled promise rejection",
      context: {
        contexts: {
          promiseRejectionEvent: {
            reason: (error as PromiseRejectionEvent).reason,
          },
        },
      },
    });
  } else {
    logMessage({
      error: "unexpected error type",
      context: {
        ...context,
        contexts: {
          ...(context && "contexts" in context ? context.contexts : undefined),
          originalError: { error },
        },
      },
    });
  }
}

function logException({ error, context }: LogParams<Error>) {
  if (config.isProduction) {
    Sentry.withScope((scope) => {
      addContextToScope(scope);
      if (context !== undefined) {
        Sentry.setContext("contexts", context);
      }
      Sentry.captureException(error);
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(error, context);
  }
}

function logMessage({ error, context }: LogParams<string>) {
  if (config.isProduction) {
    Sentry.withScope((scope) => {
      addContextToScope(scope);
      if (context !== undefined) {
        Sentry.setContext("contexts", context);
      }
      Sentry.captureMessage(error);
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(error, context);
  }
}

function addContextToScope(scope: Sentry.Scope) {
  scope.setTag("url", window.location.href);
}

window.addEventListener("beforeunload", () => {
  isPageUnloading = true;
});
