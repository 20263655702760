<template>
  <img class="h-5" :src="getIconPath(props.icon)" />
</template>

<script setup lang="ts">
import { Icon } from "@/assets/icons/Icon";

const props = defineProps<{
  icon: Icon;
}>();

function getIconPath(icon: Icon) {
  return new URL(`/src/assets/icons/${icon}`, import.meta.url).href;
}
</script>
