import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { createApp, h } from "vue";
import { GlobalEvents } from "vue-global-events";
import VueTippy from "vue-tippy";

import App from "@/App.vue";
import { initializeLogging, log } from "@/common/utils/logging";
import headerComponent from "@/components/HeaderComponent.vue";
import NavigationComponent from "@/components/NavigationComponent.vue";
import { isInstalling, isRefreshNeeded } from "@/components/ServiceWorkerUpdatePopupComponent";
import { useUser } from "@/composition-functions/user";
import { GenericFailure, ValidationError } from "@/errors/GenericFailure";
import { getGlobalTranslation, i18n } from "@/i18n";
import router from "@/router";
import { setToastMessage } from "@/util/SweetalertHelper";
import "@/util/arrayHelper";
import "@/util/monacoEditor";
import { StatusCodes } from "http-status-codes";
import "./css/index.css";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const app = createApp({
  render: () => h(App),
});

app.use(VueTippy);
app.use(i18n);
app.use(router);

app.component("GlobalEvents", GlobalEvents);
app.component("HeaderComponent", headerComponent);
app.component("NavigationComponent", NavigationComponent);

initializeLogging({ app });

app.config.errorHandler = (error) => {
  if (error instanceof GenericFailure) {
    if (error.statusCode === StatusCodes.UNAUTHORIZED) {
      const { resetAuthenticationState } = useUser();
      resetAuthenticationState();
      if (router.currentRoute.value.name && router.currentRoute.value.name !== "login") {
        router.push({ name: "login", query: { redirectPath: router.currentRoute.value.fullPath } });
      }
      setToastMessage({ icon: "error", title: `${error.message}${error.description ? `\n${error.description}` : ""}` });
    } else {
      if (isRefreshNeeded.value || isInstalling.value) {
        showUpdateMessage();
        return;
      }
      Sentry.withScope((scope) => {
        scope.setTag("logSource", "app.config.errorHandler");
        log({ error });
      });

      if (error instanceof ValidationError) {
        setToastMessage({ icon: "error", title: error.message });
        document.querySelector("[data-has-errors='true']")?.scrollIntoView({ block: "center", inline: "center" });
      } else {
        setToastMessage({ icon: "error", title: `${error.message}${error.description ? `\n${error.description}` : ""}` });
      }
    }
  } else {
    if (isRefreshNeeded.value || isInstalling.value) {
      showUpdateMessage();
      return;
    }
    Sentry.withScope((scope) => {
      scope.setTag("logSource", "app.config.errorHandler");
      log({ error });
    });

    setToastMessage({ icon: "error", title: getGlobalTranslation({ message: "message.somethingWentWrong" }) });
  }
};

function showUpdateMessage() {
  if (isInstalling.value) {
    setToastMessage({ icon: "warning", title: getGlobalTranslation({ message: "message.installingUpdates" }) });
  }
  if (isRefreshNeeded.value) {
    setToastMessage({ icon: "warning", title: getGlobalTranslation({ message: "message.updatesInstalled" }) });
  }
}

window.addEventListener("unhandledrejection", (error) => {
  Sentry.withScope((scope) => {
    scope.setTag("logSource", "window.onunhandledrejection");
    log({ error });
  });
});

app.mount("#app");
