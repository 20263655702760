import { computed, reactive, toRefs } from "vue";
import { RouteLocationRaw } from "vue-router";

import { useApp } from "@/composition-functions/app";
import { useCommon } from "@/composition-functions/common";
import { updateCsrfToken } from "@/composition-functions/csrfToken";
import { Language } from "@/composition-functions/language/Language";
import {
  IDeleteUserParams,
  IGetGlobalUserParams,
  IGetGlobalUsersParams,
  IGetUserParams,
  IGetUsersParams,
  IRedirectUserToHomeParams,
  ISaveGlobalUserParams,
  ISaveUserParams,
  ISaveUserProfileParams,
  ISendUserCredentialsParams,
  ISetAuthenticationStateParams,
  IUnblockUserParams,
} from "@/composition-functions/user/IParams";
import {
  GlobalUserDetailDto,
  GlobalUserListDto,
  UserDetailDto,
  UserEnableTwoFactorDto,
  UserListDto,
  UserProfileDto,
} from "@/composition-functions/user/UserDto";
import { UserType } from "@/composition-functions/user/UserType";
import { ApplicationTheme } from "@/enums/ApplicationTheme";
import { UserPermission } from "@/enums/UserPermission";
import { changeI18nLanguage } from "@/i18n";
import { IQueryListResult } from "@/interfaces/IResponses";
import { MandantTextsDto } from "@/mandantTexts/dto.mandantTexts";
import router from "@/router";
import mapLanguageToApplicationLanguageIsoCode from "@/util/mapLanguageToLanguageIsoCode";
import { LanguageIsoCode } from "../language/LanguageIsoCode";

const localUserState: {
  userId: null | number;
  userLanguage: null | Language;
  userLanguageIsoCode: LanguageIsoCode;
  userType: UserType;
  isTwoFactorEnabled: boolean;
  mandantIds: number[];
  isAuthenticated: boolean;
  changePassword: boolean;
  changePasswordMessage: null | string;
  globalMandantTexts: undefined | MandantTextsDto;
  applicationTheme: ApplicationTheme;
  permissions: UserPermission[];
} = reactive({
  userId: null,
  userLanguage: null,
  userLanguageIsoCode: computed(() => {
    return mapLanguageToApplicationLanguageIsoCode(localUserState.userLanguage ?? getBrowserLanguage());
  }),
  userType: UserType.NONE,
  isTwoFactorEnabled: false,
  mandantIds: [],
  isAuthenticated: false,
  changePassword: false,
  changePasswordMessage: null,
  globalMandantTexts: undefined,
  applicationTheme: ApplicationTheme.LIGHT,
  permissions: [],
});

async function enableTwoFactor(): Promise<UserEnableTwoFactorDto> {
  const { postRequest } = useCommon();

  const response = await postRequest({ path: "/admin/user/enableTwoFactor" });
  const result = await response.json();
  return result;
}

async function getGlobalUser({ userId }: IGetGlobalUserParams): Promise<GlobalUserDetailDto> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/user/global/${userId}` });
  const result = await response.json();
  return result;
}

async function getGlobalUsers({
  currentPage,
  pageSize,
  searchTerm,
}: IGetGlobalUsersParams): Promise<IQueryListResult<GlobalUserListDto>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    searchTerm,
    currentPage: currentPage.toString(),
    pageSize: pageSize.toString(),
  });

  const response = await getRequest({ path: "/admin/user/global", urlSearchParams });
  const result = await response.json();
  return result;
}

async function getUser({ userId }: IGetUserParams): Promise<UserDetailDto> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/user/${userId}` });
  const result = await response.json();
  return result;
}

async function getUsers({
  currentPage,
  pageSize,
  searchTerm,
  mandants,
  collectiveGroups,
}: IGetUsersParams): Promise<IQueryListResult<UserListDto>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    searchTerm,
    currentPage: currentPage.toString(),
    pageSize: pageSize.toString(),
    mandants: mandants.toString(),
    collectiveGroups: collectiveGroups.toString(),
  });

  const response = await getRequest({ path: "/admin/user", urlSearchParams });
  const result = await response.json();
  return result;
}

async function saveUser({ user }: ISaveUserParams): Promise<{ id: number }> {
  const { postRequest } = useCommon();

  const response = await postRequest({ path: "/admin/user", data: JSON.stringify(user) });
  const result = await response.json();
  return result;
}

async function deleteUser({ userId }: IDeleteUserParams) {
  const { deleteRequest } = useCommon();
  await deleteRequest({ path: `/admin/user/${userId}` });
}

async function getUserProfile(): Promise<UserProfileDto> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: "/admin/user/profile" });
  const result = await response.json();
  return result;
}

async function saveGlobalUser({ user }: ISaveGlobalUserParams): Promise<{ id: number }> {
  const { postRequest } = useCommon();

  const response = await postRequest({ path: "/admin/user/global", data: JSON.stringify(user) });
  const result = await response.json();
  return result;
}

async function sendUserCredentials({ userId }: ISendUserCredentialsParams) {
  const { postRequest } = useCommon();
  await postRequest({ path: `/admin/user/${userId}/sendCredentials` });
}

async function unblockUser({ userId }: IUnblockUserParams) {
  const { postRequest } = useCommon();
  await postRequest({ path: `/admin/user/${userId}/unblockUser` });
}

async function ping() {
  const { setInitialPingDone } = useApp();
  const { getRequest } = useCommon();
  const { resetAuthenticationState } = useUser();

  try {
    const response = await getRequest({ path: "/admin/user/ping" });
    const result = await response.json();
    updateCsrfToken(result);

    setAuthenticationState({
      authenticationState: true,
      userId: result.userId,
      userLanguage: result.userLanguage,
      userType: result.userType,
      mandantIds: result.mandantIds,
      globalMandantTexts: result.globalMandantTexts,
      applicationTheme: result.applicationTheme,
      isTwoFactorEnabled: result.isTwoFactorEnabled,
      permissions: result.permissions,
    });
    setInitialPingDone({ initialPingState: true });
  } catch (error) {
    resetAuthenticationState();
    setInitialPingDone({ initialPingState: true });
    throw error;
  }
}

async function redirectUserToHome(options?: IRedirectUserToHomeParams) {
  const { setInitialPingDone } = useApp();
  const { userType, mandantIds, resetAuthenticationState } = useUser();

  const replaceParams: RouteLocationRaw = {};

  if (options?.query !== undefined) {
    replaceParams.query = options.query;
  }

  if (userType.value !== UserType.NONE) {
    switch (userType.value) {
      case UserType.SUPER_ADMIN:
      case UserType.ADMIN:
        replaceParams.name = "adminDashboard";
        router.replace(replaceParams);
        break;

      case UserType.NORMAL_MULTIPLE:
        replaceParams.name = "mandantsList";
        router.replace(replaceParams);
        break;

      case UserType.NORMAL_SINGLE:
        replaceParams.name = "mandantDashboard";
        replaceParams.params = { mandantId: `${mandantIds.value[0]}` };
        router.replace(replaceParams);
        break;

      default:
        resetAuthenticationState();
        setInitialPingDone({ initialPingState: true });

        if (router.currentRoute.value.name !== "login") {
          router.push({ name: "login" });
        }
        break;
    }
  }
}

async function resetPassword({ email }: { email: string }) {
  const { postRequest } = useCommon();
  await postRequest({ path: "/admin/user/resetPassword", data: JSON.stringify({ email }) });
}

async function saveUserProfile({ user }: ISaveUserProfileParams) {
  const { postRequest } = useCommon();
  await postRequest({ path: "/admin/user/profile/set", data: JSON.stringify(user) });
}

const resetAuthenticationState = () => {
  localUserState.isAuthenticated = false;
  localUserState.userId = null;
  localUserState.userLanguage = null;
  localUserState.userType = UserType.NONE;
  localUserState.isTwoFactorEnabled = false;
  localUserState.mandantIds = [];
  localUserState.globalMandantTexts = undefined;
};

const setAuthenticationState = ({
  authenticationState,
  globalMandantTexts,
  userId,
  userLanguage,
  userType,
  mandantIds,
  applicationTheme,
  isTwoFactorEnabled,
  permissions,
}: ISetAuthenticationStateParams) => {
  localUserState.isAuthenticated = authenticationState;
  localUserState.userId = userId;
  localUserState.userLanguage = userLanguage;
  localUserState.userType = userType;
  localUserState.isTwoFactorEnabled = isTwoFactorEnabled;
  localUserState.mandantIds = mandantIds;
  localUserState.globalMandantTexts = globalMandantTexts;
  localUserState.applicationTheme = applicationTheme;
  localUserState.permissions = permissions;

  if (userLanguage !== null) {
    changeI18nLanguage(userLanguage);
  }
};

async function setPasswordChange({
  changePassword,
  changePasswordMessage,
}: {
  changePassword: boolean;
  changePasswordMessage: null | string;
}) {
  localUserState.changePassword = changePassword;
  localUserState.changePasswordMessage = changePasswordMessage;
}

async function verifyTwoFactor({ verificationCode }: { verificationCode: string }) {
  const { postRequest } = useCommon();
  await postRequest({ path: "/admin/user/verifyTwoFactor", data: JSON.stringify({ verificationCode }) });
}

async function disableTwoFactor({ userId }: { userId: number }) {
  const { postRequest } = useCommon();
  await postRequest({ path: `/admin/user/${userId}/disableTwoFactor` });
}

async function verifyTwoFactorLogin({ verificationCode }: { verificationCode: string }) {
  const { postRequest } = useCommon();

  await postRequest({ path: "/admin/user/verifyTwoFactorLogin", data: JSON.stringify({ verificationCode }) });
  await ping();
}

async function setApplicationTheme(applicationTheme: ApplicationTheme) {
  const { postRequest } = useCommon();
  await postRequest({ path: "/admin/user/setApplicationTheme", data: JSON.stringify({ applicationTheme }) });
  localUserState.applicationTheme = applicationTheme;
}

const getBrowserLanguage = (): Language => {
  switch (navigator.language.split("-")[0]) {
    case "it":
      return Language.ITALIAN;
    default:
      return Language.GERMAN;
  }
};

export const useUser = () => {
  return {
    ...toRefs(localUserState),

    enableTwoFactor,
    getUser,
    saveUser,
    deleteUser,
    getUsers,
    getGlobalUser,
    getGlobalUsers,
    saveGlobalUser,
    sendUserCredentials,
    getUserProfile,
    ping,
    redirectUserToHome,
    resetPassword,
    saveUserProfile,
    resetAuthenticationState,
    setAuthenticationState,
    setPasswordChange,
    unblockUser,
    verifyTwoFactor,
    disableTwoFactor,
    verifyTwoFactorLogin,
    getBrowserLanguage,
    setApplicationTheme,
  };
};
