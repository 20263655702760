import { useUser } from "@/composition-functions/user";
import { ApplicationTheme } from "@/enums/ApplicationTheme";
import type { editor } from "monaco-editor";
import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import cssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
import htmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";
import { Ref, watch } from "vue";

const { applicationTheme } = useUser();

self.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === "json") {
      return new jsonWorker();
    }
    if (label === "css" || label === "scss" || label === "less") {
      return new cssWorker();
    }
    if (label === "html" || label === "handlebars" || label === "razor") {
      return new htmlWorker();
    }
    if (label === "typescript" || label === "javascript") {
      return new tsWorker();
    }
    return new editorWorker();
  },
};

interface ICreateMonacoEditorInstanceParams {
  editorElement: Ref<HTMLElement>;
  editorOptions?: editor.IStandaloneEditorConstructionOptions;
  editorValue: string;
  editorLanguage: "html" | "javascript" | "json" | "markdown" | "scss";
  onChange?: (value: string) => void;
}
export const createMonacoEditorInstance = async ({
  editorElement,
  editorOptions,
  editorValue,
  editorLanguage,
  onChange,
}: ICreateMonacoEditorInstanceParams) => {
  const monaco = await import("monaco-editor");

  const monacoEditor = monaco.editor.create(editorElement.value, {
    ...editorOptions,
    automaticLayout: true,
    tabSize: 2,
    minimap: {
      enabled: false,
    },
    theme: applicationTheme.value === ApplicationTheme.DARK ? "vs-dark" : "vs-light",
    language: editorLanguage,
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
    value: editorValue,
  });

  monacoEditor.getModel()?.updateOptions({ tabSize: 2 });

  if (onChange !== undefined) {
    monacoEditor.onDidChangeModelContent(() => {
      onChange(monacoEditor.getValue());
    });
  }

  watch(applicationTheme, () => {
    applicationTheme.value === ApplicationTheme.DARK ? monaco.editor.setTheme("vs-dark") : monaco.editor.setTheme("vs-light");
  });

  return monacoEditor;
};
