<style lang="scss">
body {
  @apply bg-lightPrimaryBackgroundColor text-default leading-8 dark:bg-darkPrimaryBackgroundColor;
}

.swal2-title {
  @apply leading-normal text-lightPrimaryTextColor dark:text-darkPrimaryTextColor;
}

.swal2-html-container {
  @apply text-lightPrimaryTextColor dark:text-darkPrimaryTextColor;
}

.swal2-container {
  &.swal2-backdrop-show.swal2-center,
  &.swal2-noanimation {
    @apply bg-white/90 dark:bg-transparent/80;
  }
}

.swal2-styled.swal2-confirm {
  @apply bg-primary text-white;
}

.swal2-input,
.swal2-file,
.swal2-textarea {
  @apply border-none bg-lightTertiaryColor dark:bg-darkSecondaryColor;

  &:focus {
    @apply border-none shadow-none;
  }
}

.swal2-close:focus {
  @apply shadow-none;
}

body.swal2-toast-shown .swal2-container {
  @apply h-auto;
}

.swal2-popup.swal2-toast {
  @apply p-2;
}
</style>

<style>
.custom-toast {
  @apply !bg-lightPrimaryColor dark:!bg-darkPrimaryColor;
}

.custom-toast .swal2-close {
  @apply static;
}

.custom-toast .swal2-title,
.custom-toast .swal2-close,
.custom-toast .swal2-html-container {
  @apply text-lightPrimaryTextColor dark:text-darkPrimaryTextColor;
}

.custom-toast .swal2-timer-progress-bar {
  @apply bg-darkPrimaryColor/50 dark:bg-lightPrimaryColor/50;
}

.custom-toast .swal2-success-circular-line-left,
.custom-toast .swal2-success-circular-line-right,
.custom-toast .swal2-success-fix {
  @apply !bg-transparent;
}
</style>

<template>
  <div
    class="relative ml-auto mr-auto min-h-screen max-w-[1200px] px-xs text-lightPrimaryTextColor dark:text-darkPrimaryTextColor"
  >
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { useUser } from "@/composition-functions/user";
import { ApplicationTheme } from "@/enums/ApplicationTheme";
import { watch } from "vue";

const { applicationTheme } = useUser();

watch(applicationTheme, () => {
  applicationTheme.value === ApplicationTheme.DARK
    ? document.documentElement.classList.add("dark")
    : document.documentElement.classList.remove("dark");
});
</script>
